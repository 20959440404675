const backStageRouters = {
    path: '/backStage',
    name: 'backStage',
    component: () => import(/* webpackChunkName: "home" */ '@/views/backStage/index'),
    children: [
        {
            path: 'system/dictionary',
            component: () => import(/* webpackChunkName: "dashboard" */ '@/views/backStage/system/dictionary/index'),
            meta: {title: '字典管理'}

        },
        {
            path: 'system/config',
            component: () => import(/* webpackChunkName: "dashboard" */ '@/views/backStage/system/config/index'),
            meta: {title: '系统配置'}

        },
        {
            path: 'system/menu',
            component: () => import(/* webpackChunkName: "dashboard" */ '@/views/backStage/system/menu/index'),
            meta: {title: '菜单管理'}

        },
        {
            path: 'permission/menu',
            component: () => import(/* webpackChunkName: "dashboard" */ '@/views/backStage/permission/menu/index'),
            meta: {title: '资源管理'}

        },
        {
            path: 'permission/role',
            component: () => import(/* webpackChunkName: "dashboard" */ '@/views/backStage/permission/role/index'),
            meta: {title: '角色管理'}

        },
        {
            path: 'permission/user',
            component: () => import(/* webpackChunkName: "dashboard" */ '@/views/backStage/permission/user/index'),
            meta: {title: '用户管理'}

        },
        {
            path: 'permission/userRole',
            component: () => import(/* webpackChunkName: "dashboard" */ '@/views/backStage/permission/userRole/index'),
            meta: {title: '授权管理'}

        },
        {
            path: 'tool/toolTable',
            component: () => import(/* webpackChunkName: "dashboard" */ '@/views/backStage/tool/toolsManager'),
            meta: {title: '工具管理'}

        },
        {
            path: 'exam/exam',
            component: () => import(/* webpackChunkName: "dashboard" */ '@/views/backStage/exam/exam'),
            meta: {title: '考试管理'}

        },
        {
            path: 'exam/examDetail',
            component: () => import(/* webpackChunkName: "dashboard" */ '@/views/backStage/exam/examDetail'),
            meta: {title: '考试详情管理'}

        },
        {
            path: 'exam/examQuestion',
            component: () => import(/* webpackChunkName: "dashboard" */ '@/views/backStage/exam/examQuestion'),
            meta: {title: '考试题目管理'}
        },
        {
            path: 'exam/examError',
            component: () => import(/* webpackChunkName: "dashboard" */ '@/views/backStage/exam/examError'),
            meta: {title: '题目反馈处理'}
        }
    ]
}

export default backStageRouters
