import {getChildrenData} from "@/api/backStage/dictionary";
import Vue from "vue";

class Dict {
    constructor(dict) {
        this.dict = dict;
    }

    async init(names) {
        const ps = [];
        names.forEach((name) => {
            Vue.set(this.dict, name, []);
            let all = false;
            if (name.search('ALL-') !== -1) {
                all = true;
                name = name.replace('ALL-', '')
            }
            ps.push(
                getChildrenData({key: name, childrenOfChildren: all}).then((res) => {
                    this.dict[name] = Object.freeze(res.data);
                    localStorage.setItem(name, JSON.stringify(res.data));
                })
            );
        });
        await Promise.all(ps);
    }
}

const install = function (Vue) {
    Vue.mixin({
        data() {
            if (
                this.$options.dicts instanceof Array &&
                this.$options.dicts.length > 0
            ) {
                return {dict: {}};
            } else {
                return {};
            }
        },
        created() {
            if (this.$options.dicts instanceof Array && this.$options.dicts.length > 0) {
                new Dict(this.dict).init(this.$options.dicts);
            }
        },
    });
};

export default {install};
