import Request from '@/utils/request';

// 获取全部字典
export const getAllDbInfo = (params) => Request.get('/chick/dictionary/getAllDbInfo', { data: { ...params }, loading: false });
// 通过字典获取子项 (公共)
export const getChildrenData = (params) => Request.get('/chick/dictionary/getChildrenData', { data: { ...params }, loading: false });
// 保存字典，不带id
export const saveDB = (params) => Request.post('/chick/dictionary/save', { data: { ...params }, loading: false });
// 更新字典，带id
export const updateDB = (params) => Request.post('/chick/dictionary/update', { data: { ...params }, loading: false });
// 删除字典
export const removeDB = (params) => Request.post('/chick/dictionary/removeByDataNum', { data: { ...params }, loading: false });
