import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    dicts: {}
  },
  mutations: {
    SET_DICT(state, obj) {
      state.dicts[obj.label] = obj.value;
    }
  },
  actions: {
  },
  modules: {
  }
})
