import Request from '@/utils/request';

// 获取验证码
export const getCaptcha = (params) => Request.get('/chick/user/captcha', {data: {...params}, loading: true});
// 登录
export const login = (params) => Request.post('/chick/user/login', {data: {...params}, loading: true});
export const loginByEmail = (params) => Request.post('/chick/user/loginByEmail', {data: {...params}, loading: true});
// 注册
export const register = (params) => Request.post('/chick/user/register', {data: {...params}, loading: true});
// 邮箱注册
export const registerEmail = (params) => Request.post('/chick/user/registerEmail', {data: {...params}, loading: true});
// 通过jwtToken获取用户详细信息
export const getUserByJwt = (params) => Request.post('/chick/user/getUserByJwt', {data: {...params}, loading: true});
// 发送email注册验证码
export const sendEmailCodeRegister = (params) => Request.get('/chick/user/sendEmailCodeRegister', {data: {...params}, loading: true});
// 发送email登录验证码
export const sendEmailCodeLogin = (params) => Request.get('/chick/user/sendEmailCodeLogin', {data: {...params}, loading: true});
