/**
 * 使用方法：
 * import Request from '@/utils/request';
 *
 *
 * // promise 写法
 * Request.get(url, options).then(() => {
 *
 * });
 *
 * Request.post(url, options) // 与get类似Request.post(url, options) // 与get类似
 *
 * options: {
 *  data: {}, // 请求参数
 *  onlyOnce: true, // 防止单位时间内多次无效请求  *【非axios自带】
 *  loading: false, // 是否显示统一loading样式 *【非axios自带】
 *  form: true,  // 处理是否以form格式发送 *【非axios自带】
 *  cache: false, // 设置请求缓存时间 *【非axios自带】
 *  customCatch: true, // 是否业务捕获请求异常 *【非axios自带】
 *  timeout: 3000, // 默认为3秒，如果业务特殊需要可调整
 *  headers: {}, // 向header中添加特殊值时使用（通常用不到）
 *  ... // note: 更多options，可以参考axios的config配置，均支持
 * }
 *
 * 取消单一请求：
 * const req = Request.get('/api');
 * Request.cancel(req);
 *
 */
// import router from '@/router';
import axios from 'axios';
// import { encode } from 'js-base64';
import loadingToast from '@/utils/loading';
import { Local } from '@/utils/storage';
// import { Toast } from 'vant';
import {Message} from 'element-ui'
import { stringify } from 'qs';
import {LoginBox} from "@/router/user/login";
// import { notStartWith } from 'postcss-pxtorem/lib/filter-prop-list';

// default settings
// axios.defaults.headers['Content-Type'] = 'application/json;charset=UTF-8'
axios.defaults.headers['Content-Type'] = 'application/json';
axios.defaults.withCredentials = true;
// axios使用的默认配置
const defaultConfig = {
    withCredentials: true,
    timeout: 30000
};

// 无token授权编码
// const AuthErrorCode = 70001;
// 微信项目给所有接口加上授权标识

const instance = axios.create(defaultConfig);

const reqTokenMap = new Map();

const getCacheKey = (option) => {
    return JSON.stringify(option);
};

const getFixedOptionsKey = (options) => {
    const { url, method, params, data, form } = options;
    let filterParams = { ...params };
    if (params) {
        // eslint-disable-next-line no-unused-vars
        const { csRandomTime, ...rest } = params;
        filterParams = rest || {};
    }
    return getCacheKey({
        url,
        method,
        params: filterParams,
        data,
        form
    });
};

const processService = (option) => {
    /**
     * cache 为number 作用为接口缓存时间 单位为s
     */
    const { cache, params = {}} = option;
    let cacheData;
    option.params = {
        ...params || {},
        csRandomTime: new Date().getTime() + Math.random()
    };
    if (cache) {
        const cacheRequestKey = getFixedOptionsKey(option);
        option.cacheRequestKey = cacheRequestKey;
        cacheData = Local.get(cacheRequestKey);
    }
    if (cacheData) {
        console.info('has cache request', option);
        return new Promise(resolve => {
            resolve(cacheData);
        });
    } else {
        return fetch(option);
    }
};

// request 拦截
instance.interceptors.request.use(
    config => {
        // 增加前后端分离标识 防止授权请求302
        config.headers['request-type'] = 'client-vue';
        //如果请求中存在token,后面请求携带token
        if (window.localStorage.getItem('token') !== null) {
            config.headers['Authorization'] = window.localStorage.getItem('token');
        }
        // 拼接时间戳，防止请求缓存
        return config;
    },
    error => Promise.reject(error)
);

const defaultOptions = {
    loading: false,
    onlyOnce: true,
    form: false,
    cache: false,
    customCatch: false
};
// 用于存储onlyOnce请求
const uniqueRequestMap = {};
const getUniqueKey = ({ method, url, data, params }) =>
    `${method}|${url}|${JSON.stringify(data)}|${JSON.stringify(params)}`;

/**
 * 防止重复请求逻辑
 * @param {*} { onlyOnce, ...config }
 * @returns
 */
const processOnlyOnce = (config, key) => {
    if (uniqueRequestMap[key]) {
        throw new Error(JSON.stringify({
            resCode: 10000,
            msg: '请勿重复请求'
        }));
    }
    uniqueRequestMap[key] = true;
};
/**
 * 根据传入对象中的config，将对应key从map中移除
 * @param {*} obj 传入对象需包含config
 */
const removeRequestFromUniqueMap = uniqueKey => {
    delete uniqueRequestMap[uniqueKey];
};

const processForm = (config, encode = true) => {
    config.headers = config.headers || {};
    config.headers['Content-Type'] =
        'application/x-www-form-urlencoded';
    // const old = config.data;
    // console.log(old, '之前');
    config.data = stringify(config.data, { encode });
    // console.log(config.data, '之后');
};

/**
 * 处理通用loading
 * @param {*} loading options.loading
 */
const processLoading = (loading, msg) => {
    // loading && store.dispatch('app/showLoading')
    if (loading) {
        return loadingToast({
            message: msg,
            forbidClick: true,
            duration: 0
        });
    }
    return undefined;
};
// const urlPrefix = /(http|https):\/\/([\w.]+\/?)\S*/
const fetch = options => {
    // 声明取消请求时需使用的source
    const source = axios.CancelToken.source();
    const promise = new Promise((resolve, reject) => {
        const {
            onlyOnce, loading, form, loadingMsg, notStringFy, ...resetOptions
        } = options;
        // 关闭此请求loading的方法
        let closeLoading;
        // 唯一Key
        const { data, uploadType } = resetOptions;
        if (typeof data === 'object' && !uploadType) {
            const newDate = {};
            Object.keys(data).forEach(key => {
                const value = data[key];
                if (value !== undefined) {
                    newDate[key] = value;
                }
            });
            resetOptions.data = newDate;
        }
        const uniqueKey = getUniqueKey(resetOptions);
        // 缓存数据
        // 处理onlyOnce
        onlyOnce && processOnlyOnce(resetOptions, uniqueKey);
        // 处理form
        form && processForm(resetOptions, !notStringFy);
        // 处理公共loading逻辑
        // eslint-disable-next-line prefer-const
        closeLoading = processLoading(loading, loadingMsg);
        instance({
            ...resetOptions,
            cancelToken: source.token
        }).then(resp => {
            closeLoading && closeLoading();
            resolve(resp);
        }).catch((error) => {
            closeLoading && closeLoading();
            // Toast('服务异常，请稍后再试');
            reject(error);
        }).finally(() => {
            removeRequestFromUniqueMap(uniqueKey);
            reqTokenMap.delete(promise);
        });
    });

    reqTokenMap.set(promise, source);

    return promise;
};

// response 拦截
// instance.interceptors.response.use(
//     response => {
//         return new Promise((resolve) => {
//             const { code, data } = response.data;
//             if (code == AuthErrorCode) {
//                 const { authorize_url } = data;
//                 console.log('跳转授权', authorize_url);
//                 if (authorize_url) {
//                     const url = window.location.href;
//                     const jumpUrl = authorize_url + ',' + encode(url);
//                     window.location.replace(jumpUrl);
//                 }
//                 const close = loadingToast('授权中...');
//                 setTimeout(() => {
//                     close();
//                     resolve({ code: 1, data: {}});
//                 }, 3000);
//             } else {
//                 const { config, data } = response;
//                 const { cacheRequestKey, cache } = config || {};
//                 if (data.code == 1 && cacheRequestKey && cache) {
//                     Local.set(cacheRequestKey, data, cache * 1000);
//                 }
//                 resolve(response.data);
//             }
//         });
//     },
//     error => {
//         if (error && error.stack.indexOf('timeout') > -1) {
//             processLoading(false);
//             Toast('请求超时,即将刷新重试');
//             router.go(0);
//         }
//         return Promise.reject(error);
//     }
// );

//响应拦截器
instance.interceptors.response.use(success => {
    //业务逻辑错误
    if (success.status && success.status === 200) {
        if (success.data.code === 401 && success.config.url !== "/chick/examWeb/getExamUserSetting") {
            Message.error({message: success.data.msg});
            setTimeout(() => {
                LoginBox.install();
            }, 500 )
        }
        if (success.data.code === 1 || success.data.code === 403) {
            Message.error({message: success.data.msg});
        }
        //这里是如果只返回数据,那就不给提示,在后台控制,默认返回R就是不提示,需要提示就加msg
        if (success.data.code === 0 && success.data.msg!=='操作成功') {
            Message.success({message: success.data.msg});
        }
    }
    return success.data;
}, error => {
    //这里是备用 400+ 500+ 都在上边处理
    if (error.response.code === 504) {
        Message.error({message: "服务器被吃了"});
    } else if (error.response.code === 403) {
        Message.error({message: "权限不足"});
        //跳转到开通vip页面
    } else if (error.response.code === 401) {
        Message.error({message: "尚未登陆,请先登录"});
        LoginBox.install();
    } else {
        if (error.response.data.message) {
            Message.error({message: error.response.data.message});
        } else {
            Message.error({message: "未知错误!请联系站长"});
        }
    }
    return;
});

export default {
    get (url, options = {}) {
        return processService({
            ...defaultOptions,
            ...options,
            url,
            method: 'get',
            params: options.data
        });
    },
    delete (url, options = {}) {
        return processService({
            ...defaultOptions,
            ...options,
            url,
            method: 'delete',
            params: options.data
        });
    },
    downFile (url, options = {}) {
        return processService({
            ...defaultOptions,
            ...options,
            url,
            method: 'get',
            responseType: 'arraybuffer',
            params: options.data
        });
    },
    post (url, options = {}) {
        return processService({
            ...defaultOptions,
            ...options,
            url,
            method: 'post'
        });
    },
    form (url, options = {}) {
        return processService({
            ...defaultOptions,
            ...options,
            url,
            method: 'post',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            }
        });
    },

    upload (url, options = {}) {
        const formData = new FormData();
        Object.keys(options.data || {}).forEach(key => {
            const item = options.data[key];
            console.log(key);
            console.log(item);
            formData.append(key, item);
        });
        return fetch({
            ...defaultOptions,
            ...options,
            headers: {
                'Content-Type': 'multipart/form-data;'
            },
            url,
            form: false,
            uploadType: true,
            data: formData,
            method: 'post'
        });
    },
    cancel (req) {
        // 当传入request时，则取消对应的请求
        if (req) {
            reqTokenMap.get(req).cancel();
            reqTokenMap.delete(req);
            return;
        }
        for (const request of reqTokenMap.values()) {
            request.cancel();
        }
        reqTokenMap.clear();
    }
};
