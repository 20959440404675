const frontStageRouters = {
    path: '/',
    name: 'frontStage',
    component: () => import(/* webpackChunkName: "home" */ '@/views/frontStage/index'),
    children: [
        {
            path: '/',
            component: () => import(/* webpackChunkName: "dashboard" */ '@/views/frontStage/index/index'),
            meta: {title: '首页'}

        },
        {
            path: '/comics',
            component: () => import(/* webpackChunkName: "dashboard" */ '@/views/frontStage/comics/index'),
            meta: {title: '漫画小屋'}

        },
        {
            path: '/novel',
            component: () => import(/* webpackChunkName: "dashboard" */ '@/views/frontStage/novel/index'),
            meta: {title: '小说文学'},
            children: [
                {
                    path: '/',
                    component: () => import(/* webpackChunkName: "dashboard" */ '@/views/frontStage/novel/home'),
                    meta: {title: '小说列表'}
                },
                {
                    path: '/chapter',
                    component: () => import(/* webpackChunkName: "dashboard" */ '@/views/frontStage/novel/chapter'),
                    meta: {title: '章节列表'}
                },
                {
                    path: '/read',
                    component: () => import(/* webpackChunkName: "dashboard" */ '@/views/frontStage/novel/read'),
                    meta: {title: '阅读'}
                }
            ]
        },
        {
            path: '/video',
            component: () => import(/* webpackChunkName: "dashboard" */ '@/views/frontStage/video/index'),
            meta: {title: '动漫电影'}

        },
        {
            path: '/software',
            component: () => import(/* webpackChunkName: "dashboard" */ '@/views/frontStage/software/index'),
            meta: {title: '软件下载'}

        },
        {
            path: '/exam',
            component: () => import(/* webpackChunkName: "dashboard" */ '@/views/frontStage/exam/index'),
            meta: {title: '考试刷题'},
            children: [
                {
                    path: '/',
                    component: () => import(/* webpackChunkName: "dashboard" */ '@/views/frontStage/exam/home'),
                    meta: {title: '考试刷题'}
                },
                {
                    path: '/examDetail',
                    name: 'examDetail',
                    component: () => import(/* webpackChunkName: "dashboard" */ '@/views/frontStage/exam/detail'),
                    meta: {title: '考试详情'}

                },
                {
                    path: '/examInfo',
                    name: 'examInfo',
                    component: () => import(/* webpackChunkName: "dashboard" */ '@/views/frontStage/exam/examInfo'),
                    meta: {title: '考试内容'}

                }
            ]
        },
        {
            path: '/tools',
            component: () => import(/* webpackChunkName: "dashboard" */ '@/views/frontStage/tools/index'),
            meta: {title: '在线工具'},
            children: [
                {
                    path: '/',
                    component: () => import(/* webpackChunkName: "dashboard" */ '@/views/frontStage/tools/home'),
                    meta: {title: '在线工具'}
                },
                {
                    path: '/UUID',
                    component: () => import(/* webpackChunkName: "dashboard" */ '@/views/frontStage/tools/item/UUID'),
                    meta: {title: 'UUID在线生成'}
                },
                {
                    path: '/randomCipher',
                    component: () => import(/* webpackChunkName: "dashboard" */ '@/views/frontStage/tools/item/RandomCipher'),
                    meta: {title: '随机密码生成'}
                },
                {
                    path: '/base64',
                    component: () => import(/* webpackChunkName: "dashboard" */ '@/views/frontStage/tools/item/Base64'),
                    meta: {title: 'Base64 编码/解码'}
                },
                {
                    path: '/imageBase64',
                    component: () => import(/* webpackChunkName: "dashboard" */ '@/views/frontStage/tools/item/ImageBase64'),
                    meta: {title: '图片与Base64互转'}
                },
                {
                    path: '/createQRCode',
                    component: () => import(/* webpackChunkName: "dashboard" */ '@/views/frontStage/tools/item/createQRCode'),
                    meta: {title: '生成二维码'}
                },
                {
                    path: '/distinguishQRCode',
                    component: () => import(/* webpackChunkName: "dashboard" */ '@/views/frontStage/tools/item/distinguishQRCode'),
                    meta: {title: '识别二维码'}
                },
                {
                    path: '/hexConvert',
                    component: () => import(/* webpackChunkName: "dashboard" */ '@/views/frontStage/tools/item/HexConvert'),
                    meta: {title: '常用/任意进制转换'}
                },
                {
                    path: '/timeStamp',
                    component: () => import(/* webpackChunkName: "dashboard" */ '@/views/frontStage/tools/item/TimeStamp'),
                    meta: {title: '时间戳转换'}
                },
                {
                    path: '/jsCompress',
                    component: () => import(/* webpackChunkName: "dashboard" */ '@/views/frontStage/tools/item/JSCompress'),
                    meta: {title: 'JS压缩'}
                },
                {
                    path: '/cssCompress',
                    component: () => import(/* webpackChunkName: "dashboard" */ '@/views/frontStage/tools/item/CSSCompress'),
                    meta: {title: 'CSS压缩'}
                },
                {
                    path: '/freight',
                    component: () => import(/* webpackChunkName: "dashboard" */ '@/views/frontStage/tools/item/Freight.vue'),
                    meta: {title: '运费计算'}
                }
            ]
        }
    ]
}

export default frontStageRouters
