<template>
  <el-dialog
      :visible.sync="show"
      :append-to-body="true"
      :show-close="true"
      :lock-scroll="false"
      width="450px"
      custom-class="loginPart"
      :close-on-click-modal="false"
  >
    <div class="login" v-show="loginFlag">
        <div style="width: 100%;text-align: center">
            <el-tag type="success" style="margin-bottom: 20px">小程序中绑定邮箱，网站用邮箱登录可同步刷题数据</el-tag>
        </div>

      <div style="text-align: center">
        <el-link :underline="false" :class='loginTypeFlag === "password" ? "activeFont":"notActiveFont"'
                 @click="changeLoginType('password')">账号密码登录&nbsp;
        </el-link>
        <el-divider direction="vertical"></el-divider>
        <el-link :underline="false" :class='loginTypeFlag === "phone" ? "activeFont":"notActiveFont"'
                 @click="changeLoginType('phone')">&nbsp;短信登录
        </el-link>
        <el-divider direction="vertical"></el-divider>
        <el-link :underline="false" :class='loginTypeFlag === "email" ? "activeFont":"notActiveFont"'
                 @click="changeLoginType('email')">&nbsp;邮箱登录
        </el-link>
      </div>
      <div style="margin: 30px 30px 10px 30px">
        <el-form :model="loginForm" :rules="loginRules" ref="loginForm">
          <div class="inputElement" v-if="loginTypeFlag === 'email'">
            <el-form-item prop='email'>
              <el-input v-model='loginForm.email' placeholder='请输入邮箱账号'>
                <i slot="prefix" class="el-input__icon el-icon-message"></i>
              </el-input>
            </el-form-item>
            <el-form-item prop="emailCaptchaText">
              <el-input v-model="loginForm.emailCaptchaText" placeholder="请输入邮箱验证码"
                        style="width: 65%;margin-right: 10px">
              </el-input>
              <el-button type="primary" style="width: 32%" @click="sendEmailCodeLogin">发送验证码</el-button>
            </el-form-item>
          </div>
          <div class="inputElement" v-if="loginTypeFlag === 'password'">
            <el-form-item prop='username'>
              <el-input v-model='loginForm.username' placeholder='请输入账号'>
                <i slot="prefix" class="el-input__icon el-icon-user"></i>
              </el-input>
            </el-form-item>
            <el-form-item prop="password">
              <el-input v-model="loginForm.password" placeholder="请输入密码" show-password>
                <i slot="prefix" class="el-input__icon el-icon-lock"></i>
              </el-input>
            </el-form-item>
            <el-form-item prop="captchaText">
              <el-input v-model="loginForm.captchaText" placeholder="点击图片可更换验证码"
                        style="width: 240px;margin-right: 10px">
              </el-input>
              <img :src="captchaUrl" @click="getCaptcha" align="absmiddle" style="line-height: 40px">
            </el-form-item>
          </div>
          <div style="text-align: left">
            <el-button style="width: 48%" @click="loginOrRegister('register')">注册</el-button>
            <el-button style="width: 48%" type="primary" @click="login">登录</el-button>
          </div>
          <!--          <div class="inputElement" style="text-align: center">-->
          <!--            第三方登录-->
          <!--          </div>-->
        </el-form>
      </div>
    </div>


    <div class="login" v-show="!loginFlag">
      <div style="text-align: center">
        <el-link :underline="false" :class='registerTypeFlag === "password" ? "activeFont":"notActiveFont"'
                 @click="changeRegisterType('password')">账号密码注册&nbsp;
        </el-link>
        <el-divider direction="vertical"></el-divider>
        <el-link :underline="false" :class='registerTypeFlag === "phone" ? "activeFont":"notActiveFont"'
                 @click="changeRegisterType('phone')">&nbsp;短信注册
        </el-link>
        <el-divider direction="vertical"></el-divider>
        <el-link :underline="false" :class='registerTypeFlag === "email" ? "activeFont":"notActiveFont"'
                 @click="changeRegisterType('email')">&nbsp;邮箱注册
        </el-link>
      </div>
      <div style="margin: 20px 30px 30px 30px">
        <el-form :model="registerForm" :rules="registerRules" ref="registerForm">
          <div class="inputElement" v-if="registerTypeFlag === 'email'">
            <!--邮箱-->
            <el-form-item prop='email'>
              <el-input v-model='registerForm.email' placeholder='请输入邮箱账号'>
                <i slot="prefix" class="el-input__icon el-icon-message"></i>
              </el-input>
            </el-form-item>
            <el-form-item prop="emailCaptchaText">
              <el-input v-model="registerForm.emailCaptchaText" placeholder="请输入邮箱验证码"
                        style="width: 65%;margin-right: 10px">
              </el-input>
              <el-button type="primary" style="width: 32%" @click="sendEmailCodeRegister">发送验证码</el-button>
            </el-form-item>
          </div>
          <div class="inputElement" v-if="registerTypeFlag === 'password'">
            <!--账号密码-->
            <el-form-item prop="username">
              <el-input v-model="registerForm.username" placeholder="请输入账号">
                <i slot="prefix" class="el-input__icon el-icon-user"></i>
              </el-input>
            </el-form-item>
            <el-form-item prop="password">
              <el-input v-model="registerForm.password" placeholder="请输入密码" show-password>
                <i slot="prefix" class="el-input__icon el-icon-lock"></i>
              </el-input>
            </el-form-item>
            <el-form-item prop="passwordAgain">
              <el-input v-model="registerForm.passwordAgain" placeholder="请再次输入密码" show-password>
                <i slot="prefix" class="el-input__icon el-icon-lock"></i>
              </el-input>
            </el-form-item>
            <el-form-item prop="captchaText">
              <el-input v-model="registerForm.captchaText" placeholder="点击图片可更换验证码"
                        style="width: 240px;margin-right: 10px">
              </el-input>
              <img :src="captchaUrl" @click="getCaptcha" align="absmiddle">
            </el-form-item>
          </div>
          <div style="text-align: left">
            <el-button style="width: 48%" @click="loginOrRegister('login')">返回登录</el-button>
            <el-button style="width: 48%" type="primary" @click="register">注册</el-button>
          </div>
        </el-form>
      </div>
    </div>
  </el-dialog>
</template>

<script>
import {
  getCaptcha,
  login,
  register,
  sendEmailCodeRegister,
  sendEmailCodeLogin,
  registerEmail,
  loginByEmail
} from '@/api/user/login'

export default {
  data() {
    const validUsername = (rule, value, callback) => {
      let reg = /^(?![0-9]+$)(?![a-zA-Z]+$)[0-9A-Za-z]{8,16}$/
      if (!reg.test(value)) {
        callback(new Error('账号必须是由8-16位字母+数字组合'))
      } else {
        callback()
      }
    };
    const validatePass = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请再次输入密码'));
      } else if (value !== this.registerForm.password) {
        callback(new Error('两次输入密码不一致!'));
      } else {
        callback();
      }
    };
    const validateCaptcha = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('验证码为空'));
      } else if (value !== this.captchaTextReal) {
        callback(new Error('验证码错误!'));
      } else {
        callback();
      }
    };
    return {
      //是否是登录页面
      loginFlag: true,
      //是否是账号密码登录方式
      loginTypeFlag: 'password',
      registerTypeFlag: 'password',
      show: false,
      captchaUrl: '',
      captchaTextReal: '',
      loginForm: {
        email: '',
        emailCaptchaText: '',
        username: '',
        password: '',
        captchaText: ''
      },
      registerForm: {
        email: '',
        emailCaptchaText: '',
        username: '',
        password: '',
        passwordAgain: '',
        captchaText: ''
      },
      loginRules: {
        username: [
          {required: true, message: '账号不可为空', trigger: 'blur'},
          //{validator: validUsername, trigger: 'blur'}
          {min: 6, max: 16, message: '长度在 6 到 16 个字符', trigger: 'blur'}
        ],
        email: [
          {required: true, message: '邮箱账号不可为空', trigger: 'blur'},
          {type: 'email', trigger: ['blur', 'change'], message: '请输入正确的邮箱地址'}
        ],
        password: [
          {required: true, message: '密码为不可为空', trigger: 'blur'}
        ],
        captchaText: [
          {required: true, message: '验证码不可为空', trigger: 'blur'},
          {validator: validateCaptcha, trigger: 'blur'}
        ],
        emailCaptchaText: [
          {required: true, message: '验证码不可为空', trigger: 'blur'},
        ]
      },
      registerRules: {
        username: [
          {required: true, message: '账号不可为空', trigger: 'blur'},
          //{validator: validUsername, trigger: 'blur'}
          {min: 6, max: 16, message: '长度在 6 到 16 个字符', trigger: 'blur'}
        ],
        email: [
          {required: true, message: '邮箱账号不可为空', trigger: 'blur'},
          {type: 'email', trigger: 'blur', message: '请输入正确的邮箱地址'}
        ],
        password: [
          {required: true, message: '密码为不可为空', trigger: 'blur'}
        ],
        passwordAgain: [
          {required: true, message: '密码为不可为空', trigger: 'blur'},
          {validator: validatePass, trigger: 'blur'}
        ],
        captchaText: [
          {required: true, message: '验证码不可为空', trigger: 'blur'},
          {validator: validateCaptcha, trigger: 'blur'}
        ],
        emailCaptchaText: [
          {required: true, message: '验证码不可为空', trigger: 'blur'}
        ]
      }
    };
  },
  created() {
    this.getCaptcha();
    window.localStorage.removeItem("token");
  },
  mounted() {
  },
  methods: {
    reg(e) {
      setTimeout(() => {
        this.$Reg({isOrganization: e})
      }, 500)
      this.show = false;
    },
    //更改登录类型方法
    changeLoginType(val) {
      this.$refs.loginForm.resetFields();
      if (val == 'password') {
        this.changeSelect();
        this.loginTypeFlag = val;
      }
      if (val == 'phone') {
        this.$message.warning("短信登录暂未开放，敬请期待！");
        return;
      }
      if (val == 'email') {
        this.changeSelect();
        this.loginTypeFlag = val;
      }
    },
    //更改注册类型方法
    changeRegisterType(val) {
      this.$refs.registerForm.resetFields();
      if (val == 'password') {
        this.changeSelect();
        this.registerTypeFlag = val;
      }
      if (val == 'phone') {
        this.$message.warning("短信注册暂未开放，敬请期待！");
        return;
      }
      if (val == 'email') {
        this.changeSelect();
        this.registerTypeFlag = val;
      }
    },
    //更改登录还是注册的方法
    loginOrRegister(val) {
      this.changeSelect();
      if (val == 'register') {
        this.loginFlag = false;
      } else {
        this.loginFlag = true;
      }
    },
    //获取验证码方法
    getCaptcha() {
      getCaptcha().then(res => {
        this.captchaUrl = 'data:image/png;base64,' + res.data.captchaBase64;
        this.captchaTextReal = res.data.text;
      })
    },
    //切换清空数据
    changeSelect() {
      if (this.loginTypeFlag === 'password') {
        this.getCaptcha();
      }
      this.loginForm.username = ''
      this.loginForm.password = ''
      this.loginForm.captchaText = ''
      this.registerForm.username = ''
      this.registerForm.password = ''
      this.registerForm.passwordAgain = ''
      this.registerForm.captchaText = ''
    },
    //登录
    login() {
      if (this.loginTypeFlag === 'password') {
        this.$refs.loginForm.validate((valid) => {
          if (valid) {
            const data = {
              username: this.loginForm.username,
              password: this.loginForm.password,
              code: this.loginForm.captchaText,
              captchaText: this.captchaTextReal
            }
            login(data).then(res => {
              if (res.code === 0) {
                //存储用户token
                var token = res.data.head + res.data.token;
                window.localStorage.setItem('token', token);
                //跳转页面
                location.reload();
              }
            })
          } else {
            console.log('error submit!!');
            return false;
          }
        });
      }
      if (this.loginTypeFlag === 'email') {
        console.log("11")
        const data = {
          email: this.loginForm.email,
          code: this.loginForm.emailCaptchaText
        }
        loginByEmail(data).then(res => {
          if (res.code === 0) {
            //存储用户token
            var token = res.data.head + res.data.token;
            window.localStorage.setItem('token', token);
            //跳转页面
            location.reload();
          }
        })
      }
    },
    //注册
    register() {
      var validFlag = true;
      // 账号密码注册
      if (this.registerTypeFlag === 'password') {
        this.$refs['registerForm'].validateField(['username', 'password', 'passwordAgain', 'captchaText'], (valid) => {
          if (valid) {
            validFlag = false;
          }
        })
        if (validFlag) {
          const data = {
            username: this.registerForm.username,
            password: this.registerForm.password,
            code: this.registerForm.captchaText,
            captchaText: this.captchaTextReal
          }
          register(data).then(res => {
            if (res.code === 0) {
              //跳转页面
              this.loginOrRegister('login');
              var username = this.registerForm.username;
              this.changeSelect();
              this.loginForm.username = username;
            }
          })
        }
      }
      // 邮箱注册
      if (this.registerTypeFlag === 'email') {
        this.$refs['registerForm'].validateField(['email', 'emailCaptchaText'], (valid) => {
          if (valid) {
            validFlag = false;
          }
        })
        if (validFlag) {
          const data = {
            email: this.registerForm.email,
            code: this.registerForm.emailCaptchaText
          }
          registerEmail(data).then(res => {
            if (res.code === 0) {
              //跳转页面
              this.loginOrRegister('login');
              this.changeLoginType('email')
              this.loginForm.email = this.registerForm.email;
            }
          })
        }
      }
    },
    sendEmailCodeRegister() {
      this.$refs['registerForm'].validateField('email', (valid) => {
        if (!valid) {
          const data = {
            email: this.registerForm.email
          }
          sendEmailCodeRegister(data).then(res => {
            // if (res.code === 0) {
            //   this.$message.success("发送成功！")
            // }
          })
        }
      })
    },
    sendEmailCodeLogin() {
      this.$refs['loginForm'].validateField('email', (valid) => {
        if (!valid) {
          const data = {
            email: this.loginForm.email
          }
          sendEmailCodeLogin(data).then(res => {
            //if (res.code === 0) {
            //  this.$message.success("发送成功！")
            //}
          })
        }
      })
    }
  },
  computed: {},
  watch: {},
  components: {},
};
</script>

<style lang="scss" scoped>
/deep/ .loginPart {
  box-shadow: 0px 15px 25px rgba(0, 0, 0, .5);
  border-radius: 15px;
}


.inputElement {
  margin: 15px 0;
  line-height: 16px;
}

.login .inputElement input {
  width: 100%;
  padding: 10px 0;
  font-size: 16px;
  color: #fff;
  letter-spacing: 1px;
  margin-bottom: 30px;
  border: none;
  border-bottom: 1px solid #fff;
  outline: none;
  background: transparent;
}

.login {
  width: 100%;
  height: 100%;
}

.activeFont {
  font-size: 16px;
  color: #2d8cf0;
}

.notActiveFont {
  font-size: 16px;
  color: #606266;
}

.register .inputElement input {
  width: 100%;
  padding: 10px 0;
  font-size: 16px;
  color: #fff;
  letter-spacing: 1px;
  margin-bottom: 30px;
  border: none;
  border-bottom: 1px solid #fff;
  outline: none;
  background: transparent;
}

.register {
  width: 100%;
  height: 100%;
}
</style>


