import Vue from 'vue'
import Login from '@/views/user/login/login.vue'

const LoginBox = Vue.extend(Login)

LoginBox.install = function () {
    let instance = new LoginBox({
    }).$mount()
    Vue.nextTick(() => {
        instance.show = true
    })
}

export { LoginBox }

