import { Toast } from 'vant';
// 意图在接口请求时自己关闭自己，而不是调用clear把还在进行的接口请求loading也关闭
let toastList = [];
let num = 0;

const getUuId = () => {
  num++;
  return `${new Date().getTime()}${num}${Math.random() * 100}`;
};
const getLazyKey = key => `lazy_loading${key}`;
const lazyListObj = {};
let ToastLiving = null;

const createToast = (options = {}, id = 'default') => {
  let proOption = {};
  if (typeof options === 'string') {
    proOption.message = options;
  } else {
    proOption = {
      ...options
    };
  }
  const message = proOption.message || '请稍后...';
  const { lazy, lazyTime } = proOption;
  const create = () => {
    if (!ToastLiving) {
      ToastLiving = Toast.loading({
        ...proOption,
        forbidClick: true,
        duration: 0,
        message,
        mask: 0
      });
    } else if (ToastLiving) {
      ToastLiving.message = message;
    }
  };
  if (lazy) {
    const flag = setTimeout(() => {
      create();
    }, lazyTime || 50);
    const key = getLazyKey(id);
    lazyListObj[key] = flag;
  } else {
    create();
  }

  toastList.unshift({ id, message });
  // 如果设置多少秒自动关闭 设置一个计数器自动关闭
  if (proOption.duration) {
    setTimeout(() => {
      closeToast(id);
    }, proOption.duration);
  }
};

const closeToast = (key) => {
  toastList = toastList.filter(item => item.id !== key);
  const lazyKey = getLazyKey(key);
  const timeoutFlag = lazyListObj[lazyKey];
  if (timeoutFlag !== undefined) {
    clearTimeout(timeoutFlag);
    delete lazyListObj[lazyKey];
  }
  if (toastList.length > 0 && ToastLiving) {
    ToastLiving.message = toastList[0].message;
  } else {
    Toast.clear();
    ToastLiving = null;
  }
};

export default function Loading (options, customKey) {
  const id = customKey || getUuId();
  createToast(options, id);
  return function closeSelf () {
    closeToast(id);
  };
}
// 是否存在正在展示的loading
export function checkHasLoading () {
  const doms = document.querySelectorAll('.van-toast--loading');
  let isShow = false;
  if (!window.getComputedStyle) {
    return toastList.length > 0;
  }
  for (let i = 0; i < doms.length; i++) {
    const dom = doms[i];
    const displayCss = window.getComputedStyle(dom, false)['display'];
    if (displayCss !== 'none') {
      isShow = true;
    }
  }
  return toastList.length > 0 || isShow;
}
