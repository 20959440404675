import Vue from 'vue'
import App from './App.vue'
import router from './router';
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import Vuex from 'vuex'
import store from './store';
//登录
import {LoginBox} from '@/router/user/login'
//字典
import dict from '@/utils/dict'
import './assets/icon/iconfont.css'
Vue.prototype.$Login = LoginBox.install;
//全局过滤
import * as filters from '@/filters/filters'
Object.keys(filters).forEach(key => {
  Vue.filter(key, filters[key]) // 插入过滤器名和对应方法
})

Vue.config.productionTip = false
Vue.use(ElementUI);
Vue.use(Vuex);
Vue.use(dict);
new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')
