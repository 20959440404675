import Vue from 'vue'
import VueRouter from 'vue-router'
import backStageRouters from '../router/backStage/index'
import frontStageRouters from '../router/frontStage/index'
import userRouters from '../router/user/index'

Vue.use(VueRouter)
const routes = [
    backStageRouters,
    frontStageRouters,
    userRouters,
    {
        path: '/about',
        name: 'About',
        component: () => import('../views/About.vue')
    },
    {
        path: '/goExam',
        name: 'goExam',
        // component: () => import('../views/frontStage/exam/exam')
        component: () => import('../views/frontStage/exam/doExam')
    },
    {
        path: '/goWrongExam',
        name: 'goWrongExam',
        // component: () => import('../views/frontStage/exam/exam')
        component: () => import('../views/frontStage/exam/doWrongExam')
    },
    {
        path: '/goFavExam',
        name: 'goFavExam',
        // component: () => import('../views/frontStage/exam/exam')
        component: () => import('../views/frontStage/exam/doFavExam')
    },
    {
        path: '/:pathMatch(.*)',
        component: () => import('../views/404'),
    }
]

const router = new VueRouter({
    routes
})

export default router
